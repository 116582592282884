import React, { Fragment } from "react";
import { graphql, Link } from "gatsby";
import clsx from "clsx";
import { PageQueryProps, RemarkFile } from "~/model";
import Seo from "~/components/Seo";
import formatBlogDate from "~/utils/formatBlogDate";
import twitterIconImage from "~/images/twitter-icon.png";
import facebookIconImage from "~/images/facebook-icon.png";

type ArticleLink = RemarkFile<{
  readonly slug: string;
  readonly title: string;
  readonly date: string;
}>;

type ResearchArticlePageProps = PageQueryProps<{
  readonly file: RemarkFile<{
    readonly title: string;
    readonly date: string;
    readonly slug: string;
    readonly summary: string;
    readonly categories: ReadonlyArray<string>;
  }> & {
    readonly previousArticle?: ArticleLink;
    readonly nextArticle?: ArticleLink;
  };
}>;

function ResearchArticlePage({
  data: {
    file: {
      childMarkdownRemark: { html, frontmatter },
      nextArticle,
      previousArticle,
    },
  },
  location,
}: ResearchArticlePageProps) {
  const articleUrl = location.href;
  return (
    <div className="Content-outer">
      <main className="Main Main--blog-item">
        <section className="Main-content" data-content-field="main-content">
          <article
            className={clsx(
              "BlogItem hentry category-research-to-practice post-type-text featured",
              { "has-categories": frontmatter.categories.length > 0 },
            )}
          >
            <h1 className="BlogItem-title" data-content-field="title">
              {frontmatter.title}
            </h1>
            <div
              className="sqs-layout sqs-grid-12 columns-12"
              data-layout-label="Post Body"
              data-type="item"
            >
              <div className="row sqs-row">
                <div className="col sqs-col-12">
                  <div
                    className="sqs-block html-block sqs-block-html"
                    data-block-type="2"
                  >
                    <div
                      className="sqs-block-content"
                      dangerouslySetInnerHTML={{ __html: html }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="Blog-meta BlogItem-meta">
              {frontmatter.categories.length > 0 && (
                <span className="Blog-meta-item Blog-meta-item--categories">
                  {frontmatter.categories.map((c, i) => (
                    <Fragment key={c}>
                      <Link
                        to={`/research/category/${c}`}
                        className="Blog-meta-item-category"
                      >
                        {c}
                      </Link>
                      {i < frontmatter.categories.length - 1 && <>, </>}
                    </Fragment>
                  ))}
                </span>
              )}
              <time
                className="Blog-meta-item Blog-meta-item--date"
                dateTime={frontmatter.date}
              >
                {formatBlogDate(frontmatter.date)}
              </time>
            </div>
            <div className="BlogItem-share">
              <div
                className="Share sqs-share-buttons"
                data-item-identifier="5ae3888e758d46243a728eae"
                data-item-path="research/2018/4/27/research-to-practice"
              >
                <div className="Share-buttons">
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      articleUrl,
                    )}`}
                    className="Share-buttons-item Share-buttons-item--social"
                    target="_blank"
                    data-service="facebook"
                    rel="noreferrer"
                  >
                    <img src={facebookIconImage} alt="Facebook" height={14} />
                    <span className="Share-buttons-item-label">Facebook</span>
                    <span className="Share-buttons-item-count">0</span>
                  </a>
                  <a
                    href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                      articleUrl,
                    )}&amp;text=${encodeURIComponent(frontmatter.summary)}`}
                    className="Share-buttons-item Share-buttons-item--social"
                    target="_blank"
                    data-service="twitter"
                    rel="noreferrer"
                  >
                    <img src={twitterIconImage} alt="Twitter" height={14} />
                    <span className="Share-buttons-item-label">Twitter</span>
                  </a>
                </div>
              </div>
            </div>
          </article>
          <nav className="BlogItem-pagination clear">
            {previousArticle && (
              <Link
                to={`/research/${previousArticle.childMarkdownRemark.frontmatter.slug}/`}
                className="BlogItem-pagination-link BlogItem-pagination-link--prev"
              >
                <svg
                  className="BlogItem-pagination-link-icon"
                  viewBox="0 0 23 48"
                >
                  <g className="svg-icon">
                    <polyline
                      fill="none"
                      strokeMiterlimit="10"
                      points="21.5,1.3 2.6,23.4 21.5,45.7 "
                    ></polyline>
                  </g>
                </svg>
                <div className="BlogItem-pagination-link-content">
                  <div className="BlogItem-pagination-link-label">Previous</div>
                  <h4 className="BlogItem-pagination-link-title">
                    {previousArticle.childMarkdownRemark.frontmatter.title}
                  </h4>
                  <div className="BlogItem-pagination-link-meta">
                    <span className="BlogItem-pagination-link-meta-item BlogItem-pagination-link-meta-item--author">
                      Vidya Raman
                    </span>
                    <time
                      className="BlogItem-pagination-link-meta-item BlogItem-pagination-link-meta-item--date"
                      dateTime={
                        previousArticle.childMarkdownRemark.frontmatter.date
                      }
                    >
                      {formatBlogDate(
                        previousArticle.childMarkdownRemark.frontmatter.date,
                      )}
                    </time>
                  </div>
                </div>
              </Link>
            )}
            {nextArticle && (
              <Link
                to={`/research/${nextArticle.childMarkdownRemark.frontmatter.slug}/`}
                className="BlogItem-pagination-link BlogItem-pagination-link--next"
              >
                <div className="BlogItem-pagination-link-content">
                  <div className="BlogItem-pagination-link-label">Next</div>
                  <h4 className="BlogItem-pagination-link-title">
                    {nextArticle.childMarkdownRemark.frontmatter.title}
                  </h4>
                  <div className="BlogItem-pagination-link-meta">
                    <span className="BlogItem-pagination-link-meta-item BlogItem-pagination-link-meta-item--categories">
                      Research to Practice, Rationale
                    </span>
                    <span className="BlogItem-pagination-link-meta-item BlogItem-pagination-link-meta-item--author">
                      Vidya Raman
                    </span>
                    <time
                      className="BlogItem-pagination-link-meta-item BlogItem-pagination-link-meta-item--date"
                      dateTime={
                        nextArticle.childMarkdownRemark.frontmatter.date
                      }
                    >
                      {formatBlogDate(
                        nextArticle.childMarkdownRemark.frontmatter.date,
                      )}
                    </time>
                  </div>
                </div>
                <svg
                  className="BlogItem-pagination-link-icon"
                  viewBox="0 0 23 48"
                >
                  <g className="svg-icon">
                    <polyline
                      fill="none"
                      strokeMiterlimit="10"
                      points="1.5,45.7 20.4,23.5 1.5,1.3 "
                    ></polyline>
                  </g>
                </svg>
              </Link>
            )}
          </nav>
        </section>
      </main>
    </div>
  );
}

export const pageQuery = graphql`
  query ResearchArticlePageQuery($id: String!) {
    file(id: { eq: $id }) {
      childMarkdownRemark {
        html
        frontmatter {
          title
          slug
          date
          summary
          categories
        }
      }
      previousArticle {
        childMarkdownRemark {
          frontmatter {
            slug
            title
            date
          }
        }
      }
      nextArticle {
        childMarkdownRemark {
          frontmatter {
            slug
            title
            date
          }
        }
      }
    }
  }
`;

export function Head({
  data: {
    file: {
      childMarkdownRemark: { frontmatter },
    },
  },
}: ResearchArticlePageProps) {
  return <Seo title={`${frontmatter.title} - Research`} />;
}

export default ResearchArticlePage;
